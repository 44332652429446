<template>
  <div class="bo" >
    <h1>Modifier</h1>
    <BoGalleryFormUpdate />
  </div>
    
</template>

<script>
import BoGalleryFormUpdate from '@/components/BoGalleryFormUpdate.vue'

export default {
  name: 'BoGallery',
  components: {
    BoGalleryFormUpdate
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">


</style>
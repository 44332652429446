<template>
    <div id="admin_bar" @mouseover="openMenu()" @mouseleave="closeMenu()" >
        <div id="admin_bar_arrow" v-show="!open">
            <p>
                &#9658;
            </p>
        </div>
        <Transition name="slide">
            <div id="admin_bar_menu" v-show="open || $route.fullPath.startsWith('/bo')">
                <div class="admin_bar_menu_top" >
                    <div class="logo" >
                        <img src="../assets/logocourt_blanc.svg" alt="Logo d'AudioSoft">
                    </div>
                    <ul class="admin_bar_menu_list" >
                        <!-- <li @click="goTo('/bo');toggleModaleEnter(false)"><router-link to="/bo">Dashboard</router-link></li> -->
                        <li v-if="level === 'root' " @click="goTo('/bo/site');toggleModaleEnter(false)"><router-link to="/bo/site">Param Site {{pic}}</router-link></li>
                        <li v-if="level === 'root' || level === 'edit' " @click="goTo('/bo/gallery');toggleModaleEnter(false)"><router-link to="/bo/gallery">Galerie</router-link></li>
                        <li v-if="level === 'root' || level === 'edit' " @click="goTo('/bo/mean');toggleModaleEnter(false)"><router-link to="/bo/mean">Moyens</router-link></li>
                        <li v-if="level === 'root' " @click="goTo('/bo/users');toggleModaleEnter(false)"><router-link to="/bo/users">Utilisateurs</router-link></li>
                        <li v-if="level === 'root' " @click="goTo('/bo/drop-down');toggleModaleEnter(false)"><router-link to="/bo/drop-down">Menu</router-link></li>
                        <li @click="goTo('/');toggleModaleEnter(true)"><router-link to="/">Retour au Site Public</router-link></li>
                    </ul>
    
                </div>
                <div class="admin_bar_menu_bottom">
                    <div class="user_box" >
                        <img v-if="url_pic" :src="url_pic" :alt="'Photo de ' + name" @click="goTo('/bo/users/' + id)">
                        <img v-else src="../assets/dafault.png" alt="Pas de photo de profile">
                        <div class="logout" @click="logout()" >
                            <p><i class="fas fa-power-off" v-if="myData" ></i></p>
                            <p>Déconnexion</p>
                        </div>
                    </div>
                </div>
            </div>

        </Transition>


    </div>
 <!-- <Bot />    -->
</template>
<script>
//import VueJwtDecode from 'vue-jwt-decode'
//import Bot from '../components/Bot.vue'

export default {
  name: 'NavBar',
  components: {
    //Bot
  },
  props: {
    url_pic: String,
    name: String,
    id: String,
    level: String,
    toggleModaleEnter: String
  },
  data: function () {
    return {
      open: true,
      token: '',
      decodedToken: '',
      myData: {
        "userId": 0,
        "iat": 0,
        "exp": 0
      },

    }
  },
  methods: {
    openMenu: function(){return this.open = true},
    closeMenu: function(){return this.open = false},
    goTo: function(url) {this.$router.push(url)},
    logout: function() {
      localStorage.removeItem('token');
      location.reload();
    }
  },
  mounted () {
    setTimeout(() => {
        this.open = false;
    }, "3000")


  },
}
</script>

<style scoped lang="scss">
li {
    cursor: pointer;
    * {
        font-family: 'Chakra Petch', sans-serif;
        font-weight: bold;
        font-size: 1.3rem;
    }
    a {
        color: #DDDDDDDD;
    }
    &:hover{
        background: #FFFFFF33;
    }
}
.router-link-active {
    text-shadow: #FFF 1px 0 10px;
    color: #FFF;
}
#admin_bar {
    z-index: 6;
    position: fixed;
    left: 0;
    height: 100%;
    display: flex;
    @media (max-width: 899px) {
        display: none;
    }
}
#admin_bar_menu,  #admin_bar_arrow{
    background: #001024;

}

#admin_bar_menu {
    min-width: 305px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
}

.logo {
    padding: 1rem;
    text-align: center;
    img {
      max-width: 196px;
    }
}
.admin_bar_menu_list {
    display: flex;
    flex-direction: column;
}

#admin_bar_arrow {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
        cursor: pointer;
    }
}
.user_box {
    text-align: center;
    img {
        cursor: pointer;
        width: 150px;
        height: 150px;
        border: solid 4px white;
        border-radius: 10em;
    }

}
.logout {
    cursor: pointer;
    display: flex;
    justify-content: center;
    p {
        font-size: 1.5rem;
        margin-left: 8px;
    }
    &:hover {
        background: #FFFFFF33;
    }
}

//Transition AdminBar
.slide-enter-active {
  transition: all 0.3s ease-out;
}

.slide-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-205px);
}

</style>
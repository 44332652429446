<template>
    <div class="audience" >
        <p class="title" >{{label}}</p>
        <p class="nb" >{{nb}}</p>
    </div>
</template>

<script>
export default {
  name: 'BoAudienceLight',
  props: {
    label: String,
    nb: String
  },
  data: function () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
* {
    text-align: center;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: bold;
}
.audience {
    width: 245px;
    height: 245px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    border: solid 2px;
}
.title {
    color: #001024;
    background: white;
    margin-top: 0;
    font-size: 1.1rem;
}
.nb {
    font-size: 9rem;
    margin: 0;
}
</style>
<template>
    <div class="bo" >
      <h1>Gestion du menu déroulant</h1>
      <div class="users_page" >
        <BoDropDown/>
      </div>
    </div>
    
</template>

<script>
import BoDropDown from '@/components/BoDropDown.vue'

export default {
  name: 'BoHome',
  components: {
    BoDropDown
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {

    }
  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
.users_page {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>
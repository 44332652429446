<template>
  <div class="admin_user">
    <div class="users_header" >
      <h2>Utilisateurs enregistrés</h2>
    </div>
    <div class="all_users">


      <div class="on_user">
        <div class="cell">
          <p>E-mail</p>
        </div>
        <div class="cell">
          <p>Nom Prénom</p>
        </div>
        <div class="cell">
          <p>Métier</p>
        </div>
        <div class="cell">
          <p>Niveau</p>
        </div>
        <div class="cell">
          <p>Compte Actif</p>
        </div>
        <!--<div class="cell">
          <p>Supprimer l'utilisateur</p>
        </div>-->
      </div>


      <div class="on_user " :class="{even: index % 2 === 0}" v-for="(user, index) in users"  :key="user">
        <!--<div class="cell_min">
          <p>{{user.id}}</p>
        </div>-->
        <div class="cell">
          <p>{{user.email}}</p>
        </div>
        <div class="cell">
          <p>{{user.name}}</p>
        </div>
        <div class="cell">
          <p>{{user.job}}</p>
        </div>
        <div class="cell">
          <p @click="editLevel(user.id, 'root')" v-if="user.level !== 'root'" class="add_stat" >Administrateur</p><p v-if="user.level === 'root'" class="stat" >Administrateur</p>
          <p @click="editLevel(user.id, 'edit')" v-if="user.level !== 'edit'" class="add_stat" >Rédacteur</p><p v-if="user.level === 'edit'" class="stat" >Rédacteur</p>
          <p @click="editLevel(user.id, 'user')" v-if="user.level !== 'user'" class="add_stat" >Utilisateur</p><p v-if="user.level === 'user'" class="stat" >Utilisateur</p>
        </div>
        <div class="cell">
          <img v-if="user.status === 'shadow'" src="../assets/ghost.svg" alt="ghost" />
          <img v-if="user.status === 'active'" src="../assets/check-solid.svg" alt="ok" />
          <img v-if="user.status === 'inactive'" src="../assets/xmark-solid.svg" alt="no" />
        </div>
        <!--<div class="cell">
          <i class="fas fa-user-minus fa-2x"></i>
        </div>-->
      </div>

    </div>
  </div>
</template>

<script>
const axios = require('axios');
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'AdminUsers',
  data: function () {
    return {
      users: [''],
      token: '',
      myUser: '',
      ggg: true

    }
  },
  methods: {
    getAllUsers: function () {
      axios.get(process.env.VUE_APP_API + 'adm/' + 'accounts/')
      .then(response => (this.users = response.data))
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
    editLevel: function (id, newLevel) {
      axios.put(process.env.VUE_APP_API + '' + 'adm/level/' + id, {
        level: newLevel
      })
      this.getAllUsers()
    }
  },
  mounted () {
    this.token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
    this.myUser = VueJwtDecode.decode(this.token);
    this.getAllUsers()
  }
}

</script>

<style scoped lang="scss">

</style>

<template>
  <div id="Gallery" class="Page Gallery">
    <h1>Galerie</h1>
    <!--<GalleryComp/>-->
    <GallerySplide/>
  </div>
  <router-link to="/legales" class="link_legales">MENTIONS LÉGALES</router-link>
</template>

<script>
// @ is an alias to /src
//import GalleryComp from '../components/GalleryComp.vue'
import GallerySplide from '../components/GallerySplide.vue'

export default {
  name: 'Gallery',
  components: {
    //GalleryComp,
    GallerySplide
  }
}
</script>

<style scoped lang="scss">
#Gallery {
  background: url("../assets/gallerybackground.jpg");
  background-size: 100% 100%;
  position: fixed;
  top:0; right: 0; bottom: 0; left: 0;
  z-index: 0;
  @media (max-width: 899px) {
    display: flex;
    flex-direction: column;
    background-size: auto 100%;
  }
  @media (min-width: 1500px) {
    background-size: 100% auto;
  }

}
</style>

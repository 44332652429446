<template>
  <div id="activate" class="Page activate">
    <!-- <NavBar :toggleModaleEnter="toggleModaleEnter" /> -->
    <h1>Activation de compte</h1>
    <p>{{mess}}</p>
  </div>
  <router-link to="/legales" class="link_legales">MENTIONS LÉGALES</router-link>
</template>

<script>
// import NavBar from '@/components/NavBar.vue'
const axios = require('axios');

export default {
  name: 'Activate',
  components: {
    // NavBar
  },
  props: {
    toggleModaleEnter: String,
  },
  data: function () {
    return {
      mess: '',

    }
  },
  mounted () {
    axios.put(process.env.VUE_APP_API + '' + 'team/activate/'+this.$route.params.email+'/'+this.$route.params.token)
    .then(message => (this.mess = message.data.res))
    .catch(function (error) {
      // handle error
      console.log(this.mess);
      console.log(error);
    })

  }
}
</script>

<style lang="scss">
#activate {
  z-index: 0;
  position: fixed;
  top:0; right: 0; bottom: 0; left: 0;
  background: url("../assets/teambackground.jpg");
  background-size: 100% 100%;
  p {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
  }
  @media (max-width: 1499px) {
    overflow: auto;
    background-size: auto 100%;
  }

}



</style>

<template>
  <transition name="intro">
    <Enter v-if="enter && !/^\/(bo|activate|matrix)/.test($route.fullPath)"
    :enter="enter" 
    :toggleModaleEnter="toggleModaleEnter" 
    :msg="audioSoftInfos[0].welcome_text" 
    :facebook="audioSoftInfos[0].facebook" 
    :youtube="audioSoftInfos[0].youtube" 
    :instagram="audioSoftInfos[0].instagram" 
    :loadingEnter="loadingEnter" 
  />
  </transition>
  <AdminBar v-if="user" :toggleModaleEnter="toggleModaleEnter" :showLogin="showLogin" :id="user[0].id" :url_pic="user[0].url_pic" :name="name" :level="user[0].level" />
  <NavBar v-if="$route.path === '/' || $route.path === '/means' || $route.path === '/about' || $route.path === '/contact' || $route.path === '/legales'" :pic="pic" :id="id" :toggleModaleEnter="toggleModaleEnter" :showLogin="showLogin" />
  <LogIn :varShowLogin="login" />
  <router-view :toggleModaleEnter="toggleModaleEnter" :showLogin="showLogin" />
  <!--<p style="z-index:900; position: absolute; top:0;left: 0;">{{$route.path}}</p>-->
</template>

<script>
console.log('%c                          ,                                                     ', 'color:#59546f;');
console.log('%c                         /##,                                                   ', 'color:#59546f;');
console.log('%c                        #####(                                                  ', 'color:#59546f;');
console.log('%c                      (########.                                                ', 'color:#59546f;');
console.log('%c                      ###########*                                              ', 'color:#59546f;');
console.log('%c                       .##########*                                             ', 'color:#59546f;');
console.log('%c                 .:     .(##########                                            ', 'color:#59546f;');
console.log('%c                *##.      .##########(                                          ', 'color:#59546f;');
console.log('%c               ######.      .##########                                         ', 'color:#59546f;');
console.log('%c             /########.      .(#########(      #.       ########################', 'color:#59546f;');
console.log('%c            ###########.        ##########,#(#####.      #######################', 'color:#59546f;');
console.log('%c          /###########.          /#################(&     ######################', 'color:#59546f;');
console.log('%c        .############.            ##################        ####################', 'color:#59546f;');
console.log('%c       /###########.                (############## (                           ', 'color:#59546f;');
console.log('%c      ############.                    ############.                            ', 'color:#59546f;');
console.log('%c    .###########.                      .(###########.......................     ', 'color:#59546f;');
console.log('%c  .############.                        .#################################*&    ', 'color:#59546f;');
console.log('%c .###########.                            .(##################################. ', 'color:#59546f;');
console.log('%c                                           .,##################################%', 'color:#59546f;');
console.log('%c                                                 ...................,###########', 'color:#59546f;');
console.log('%c                                                                       #########', 'color:#59546f;');
console.log('%c                                                                     .##########', 'color:#59546f;');
console.log('%c                                        ####################################### ', 'color:#59546f;');
console.log('%c                                        ######################################  ', 'color:#59546f;');
console.log('%c                                        ###################################,    ', 'color:#59546f;');
console.log('%c                                        ###############################         ', 'color:#59546f;');
console.log("%c    " + 'AudioSoft V1.1' + "   ", "border-radius: 57px;background: #59546f;box-shadow:  28px 28px 56px #423e52,-28px -28px 56px #706a8c;");
console.log("%c   API: " + process.env.VUE_APP_API + "   ", "border-radius: 57px;background: #59546f;box-shadow:  28px 28px 56px #423e52,-28px -28px 56px #706a8c;");


import Enter from './components/Enter.vue'
import NavBar from '@/components/NavBar.vue'
import AdminBar from '@/components/AdminBar.vue'
import LogIn from '@/components/LogIn.vue'
const axios = require('axios');
import VueJwtDecode from 'vue-jwt-decode'


export default {
  name: 'App',
  components: {
    Enter,
    NavBar,
    AdminBar,
    LogIn
  },
  data: function () {
    return {
      audioSoftInfos: [''],
      enter: true,
      loadingEnter: true,
      user: false,
      pic: false,
      id: '',
      login: false,
      //token: '',
      //decodedToken: '' A voir
    }
  },
  mounted () {

    axios.get(process.env.VUE_APP_API)
    .then(response => (this.audioSoftInfos = response.data), (this.loadingEnter = false))
    .catch(function (error) {
      // handle error
      this.audioSoftInfos = error;
      this.loadingEnter = true;
    })
    if (localStorage.getItem('token') != undefined || localStorage.getItem('token') != null) {
      this.token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
      this.decodedToken = VueJwtDecode.decode(this.token);
      if (this.decodedToken != null) {
        axios.get(process.env.VUE_APP_API + '' + 'team/' + this.decodedToken.userId)
          .then(response => (this.user = response.data, this.pic = response.data[0].url_pic, this.id = response.data[0].id))
      }

    }
    // Vérifie si le jeton d'accès a expiré
    if (this.token && this.decodedToken.exp && this.decodedToken.exp < Date.now() / 1000) {
      localStorage.removeItem('token'); // Supprime le jeton de connexion du stockage local
      this.token = null;
      this.decodedToken = null;
      axios.defaults.headers.common['Authorization'] = null; // Supprime l'en-tête d'autorisation
      location.reload();
    }
  },
  methods: {
    toggleModaleEnter: function(action) {
      this.enter = action
    },
    showLogin: function() {
      this.login = !this.login
    }
    
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
* {
  color: white;
}
p {
  font-family: 'Source Sans Pro', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Chakra Petch', sans-serif;
}
h1 {
  z-index: -1;
  font-size: 13em;
  line-height: 1em;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff33;
  position: absolute;
  bottom: .2em; left: .2em;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
  @media (max-width: 900px) {
    font-size: 5em;
    transform: rotate(-90deg);
    bottom: 1.7em; left: -1.3em;
  }
    @media (min-width: 900px) and (max-width: 1499px) {
      font-size: 8em;
    }
}
h3 {
  font-size: 3.5em;
  margin: 40px 0 0;
}
a {
  color: white;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
body {
  margin: 0;
  background-color: #001024;
  //background: url("assets/test.jpg");
  //background-size: 100%;
  //background-position: 0, 25px;
  //background-repeat: no-repeat;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.Page {
  padding: 9rem 0 0 0;
  overflow: auto;
  scrollbar-color: #59546f #fff0;
  scrollbar-width: thin;
}


.intro-enter-active {
  animation: loadingEnterWindow 400ms;
}
.intro-leave-active {
  animation: loadingEnterWindow 400ms reverse;
}

form {
  display: flex;
  flex-direction: column;
}
input, textarea, button {
  background: #001024aa;
  border: solid 3px white;
  font-size: 25px;
  margin: 0 0 1.5em 0;
  padding: .4em;
}
.form_btn {
  z-index: 6;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  input, button {
    cursor: pointer;
    width: 8em;
    background: white;
    color: #001024;
  }
}

@media (max-width: 900px) {
  .just_for_desk {
    display: none;
  }
}

@media (min-width: 899px) {
  .just_for_gsm {
    display: none;
  }
}

@keyframes loadingEnterWindow {
  0% { transform: translateY(-100%);}
  100% { transform: translateY(0);}
}

@keyframes loadingRight {
  0% { transform: translateX(208px);}
  0% { opacity: 0; }
  100% { transform: translateX(0px);}
  100% { opacity: 1; }
}
@keyframes loadingLeft {
  0% { transform: translateX(-208px);}
  0% { opacity: 0; }
  100% { transform: translateX(0px);}
  100% { opacity: 1; }
}
@keyframes loadingTop {
  0% { transform: translateY(-208px);}
  0% { opacity: 0; }
  100% { transform: translateY(0px);}
  100% { opacity: 1; }
}
.link_legales {
  position: fixed;
  bottom: 8px; right: 8px;
  z-index: 2;
}



</style>

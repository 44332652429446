<template>
  <form method="post" @submit.prevent="submitPost">
    <section>
      <div class="item">
        <div class="header_input" >
          <h2>Ajout d'un nouvel élément à la galerie</h2>
        </div>
        <label for="file">Image</label>
        <input type="file" ref="fileInput" @change="handleFileChange" accept=".jpg, .jpeg, .png, .gif, .svg, .webp" />
        <img class="preview" :src="file" alt="">
        <!-- <input class="medium_input" type="file" id="file" ref="file" name="media" placeholder="Media" @change="handleFileUpload()"> -->
      </div>
      <div class="item">
        <label for="title">Titre</label>
        <input class="medium_input" id="title" type="text" name="title" placeholder="Votre titre ici" v-model="title">
      </div>
      <div class="item">
        <label for="text">Texte</label>
        <textarea id="text" v-if="editor === 'code'" name="description" rows="8" cols="80"
          v-model="description"></textarea>
      </div>

      <input v-if="form" class="button" type="submit" value="Envoyer" />
    </section>
    
    
  </form>
  <Res v-if="res" :mes="res"/>

</template>

<script>
import axios from 'axios'
import { readAndCompressImage } from 'browser-image-resizer';
import Res from '@/components/ResApi.vue'

export default {
  props: {
    attachment: String

  },
  name: 'AdminUsers',
  components: {
    Res
  },
  data: function () {
    return {
      form: true,
      token: '',
      title: '',
      description: '',
      file: '',
      editor: 'code', /////////////////////////////////////////////////////////////// Refaire l'éditeur wysywyg
      res: null
    }
  },
  methods: {
    async handleFileChange() {
      const file = this.$refs.fileInput.files[0]
      const maxSize = (500 * 1024) * 2; // 500 KB X 2
      const resizedFile = await readAndCompressImage(file, { maxSize });
      const reader = new FileReader()
      reader.onload = (event) => {
        const base64 = event.target.result
        this.file = base64
      }
      reader.readAsDataURL(resizedFile)
    },
        async submitPost() {
          const formData = new FormData();
          formData.append('media', this.file);
          formData.append('title', this.title);
          formData.append('description', this.description);
          if (this.$route.params.id !== undefined) {
            formData.append('attachment', this.$route.params.id);
          }
          await axios.post(process.env.VUE_APP_API + 'gallery/add/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res => {
            this.res = res.data.res;
            this.form = false;

          });
    },

  },
  mounted () {
    // Identifier l'utilisateur
    this.token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
  },
}

</script>

<style scoped lang="scss">
.preview {
  width: 300px;
}
</style>

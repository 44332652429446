<template>
    <div class="bo" >
      <h1>Gestion des utilisateurs</h1>
      <div class="users_page" >
        <BoAdminUsers/>
        <p>Toutes les adress e-mail en @audiosoft.fr peuvent créer un compte.</p>
        <BoWhiteList/>
      </div>
    </div>
    
</template>

<script>
import BoAdminUsers from '@/components/BoAdminUsers.vue'
import BoWhiteList from '@/components/BoWhiteList.vue'

export default {
  name: 'BoHome',
  components: {
    BoAdminUsers,
    BoWhiteList
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {

    }
  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
.users_page {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>
<template>
  <div class="bo">
    <h1>Ajouter un moyen</h1>
    <div class="new_post">
      <form class="" method="post" @submit.prevent="submitPost">
        <input type="text" name="title" placeholder="Votre titre ici" v-model="title">
        <input type="file" id="file" ref="fileInput" name="media" placeholder="Media" @change="handleFileChange()"
          accept=".jpg, .jpeg, .png, .gif, .svg, .webp" />
        <img class="preview" :src="file" alt="">
        <textarea name="description" rows="8" cols="80" v-model="description"></textarea>
        <input v-if="!mes && !err" class="button" type="submit" value="Envoyer" />
      </form>
      <Res v-if="mes" :mes="mes" />
      <p class="err" v-if="err">{{ err }}</p>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { readAndCompressImage } from 'browser-image-resizer';
import Res from '@/components/ResApi.vue'

export default {
  name: 'Admin',
  components: {
    Res
  },
  data: function () {
    return {
      token: '',
      title: '',
      description: '',
      file: '',
      mes: null,
      err: null
    }
  },
  methods: {
    async handleFileChange() {
      const file = this.$refs.fileInput.files[0]
      const maxSize = (500 * 1024) * 2; // 500 KB X 2
      const resizedFile = await readAndCompressImage(file, { maxSize });
      const reader = new FileReader()
      reader.onload = (event) => {
        const base64 = event.target.result
        this.file = base64
        console.log(this.file)
      }
      reader.readAsDataURL(resizedFile)
    },
    async submitPost() {
      const formData = new FormData();
      formData.append('url_pic', this.file);
      formData.append('title', this.title);
      formData.append('description', this.description);
      await axios.post(process.env.VUE_APP_API + 'means/add/', {
        url_pic: this.file,
        title: this.title,
        description: this.description
      })
      .then(response => (this.mes = 'Élément ajouté aux moyens: ' + response.data.res.title))
      .catch(response => (this.err = 'Oulala, il y a eu un pépin avec l\'API :-( ' + response.data))

    },
  },
  mounted() {
    // Identifier l'utilisateur
    this.token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
  },
}

</script>
<style scoped lang="scss">
.preview {
  width: 300px;
}
</style>
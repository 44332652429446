<template>
    <div class="bo" >
        <BoAudienceLight label="Nombre de visite aujourd'hui" :nb="3" />
        <BoAudienceLight label="Nombre de visite du mois" :nb="26" />
    </div>
    
</template>

<script>
import BoAudienceLight from '../components/BoAudienceLight.vue'

export default {
  name: 'BoHome',
  components: {
    BoAudienceLight
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {

    }
  },
  methods: {

  }
}
</script>
<style lang="scss">
.bo {
  h1{
    position: initial;
    font-size: 5rem;
  }
  h2 {
  margin: 0 0 0 1.2rem;
  padding: 0 1rem;
  position: relative;
  bottom: .6em;
  background: #001024;
  display: block;

}
  margin: 1em;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;
  * {
    color: white;
  }
  input, textarea, button {
    font-size: 1.5rem;
  }
  .litle_input {
    width: 150px;
  }
  .medium_input {
    width: 350px;
  }
  .big_input {
    width: 700px;
  }
  
  @media (max-width: 899px) {
    height: 90vh;
    
  }
  @media (min-width: 900px) {
    margin-left: 305px;
    
  }
  section {
  border: solid 1px white;
  margin: 2rem;
  padding: 0 2rem 2rem 2rem;
  }
}
.header_input{
  display: flex;
}
.inputs_container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;

}
.item {
  display: flex;
  flex-direction: column;
}
.bo_btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 2px white;
  margin: 5px;
  padding: 5px;
  transition-duration: 400ms;
  &:hover {
    background: white;
    * {
      color: #001024;
    }
  }
  p {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
.bo_btn_err {
  border: solid 2px red;
  *{
    color: red;
  }
  &:hover {
    background: red;
  }
}
.users_header {
  display: flex;
}
.admin_user {
  z-index: 1;
  margin: 2rem;
  //margin-left: auto;
  //margin-right: auto;
  padding: 0 1em 1em 1em;
  border: solid 1px white;
}
.on_user {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 8px
}
.even {
  background: #FFFFFF22;
}
.cell, .cell_min {
  //border: solid white 1px;
  text-align: center;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}
.cell {
  width: 200px;
  img {
    max-width: 32px;
  }
}
.cell_min {
  width: 25px;
}
.add_stat, .stat {
  margin: 0
}
.add_stat{
  cursor: pointer;
  &:hover{
    color: black;
    background-color: white;
  }
}
.stat {
  color: black;
  background-color: white;
}
</style>
<template>
  <div class="bo">
    <h1>Gestion des Moyens</h1>
    <!-- <div class="btn_nav_left">
      <router-link to="/as-add-means"></router-link>
        <p>
          <i class="fas fa-plus"></i> Ajouter un nouvel élément
        </p>
      </router-link>
    </div> -->
    <div id="allElmentsGallery" class="allElmentsGallery">
      <router-link to="/bo/mean/new">
        <div class="oneElmentGallery">
          <h3>Ajouter un nouvel élément</h3>
          <i class="fas fa-plus fa-7x"></i>
        </div>
      </router-link>
      <div v-for="elementGallery in elementsGallery" :key="elementGallery" :id="elementGallery.id"
        class="oneElmentGallery">
        <router-link :to="{ name: 'BoMeanUpdate', params: { id: elementGallery.id } }">
          <h3>{{ elementGallery.title }}</h3>
        </router-link>
        <img :src="elementGallery.url_pic" :alt="elementGallery.title" />
        <div class="ctrlGallery">
          <router-link :to="{ name: 'BoMeanUpdate', params: { id: elementGallery.id } }">
            <div class="bo_btn">
              <p>Modifier</p>
            </div>
          </router-link>
          <div class="bo_btn bo_btn_err" @click="removeElement(elementGallery.id)">
            <p>Supprimer !</p>
          </div>
        </div>
      </div>
    </div>
    <p>{{ msg }}</p>
  </div>
</template>

<script>
const axios = require("axios");
//import BoAudienceLight from '../components/BoAudienceLight.vue'

export default {
  name: "BoHome",
  components: {
    //BoAudienceLight
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {
      token: "",
      myUser: "",
      elementsGallery: "",
      msg: "",
    };
  },
  methods: {
    removeElement: function (idToRemove) {
      console.log("remove " + idToRemove + " ?");
      let elements = document.getElementById("allElmentsGallery");
      let thisElement = document.getElementById(idToRemove);
      elements.removeChild(thisElement);
      axios
        .delete(process.env.VUE_APP_API + "" + "means/" + idToRemove)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    // Identifier l'utilisateur
    this.token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
    axios
      .get(process.env.VUE_APP_API + "" + "means/")
      .then((response) => (this.elementsGallery = response.data))
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  },
};
</script>
<style scoped lang="scss">
h3 {
  font-size: 0.8rem;
  margin: 1rem;
  padding: 0;
  position: relative;
  //bottom: .6em;
  //background: #001024;
  display: block;
}

.allElmentsGallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2em;
  //border: solid 1px white;
}

.oneElmentGallery {
  text-align: center;
  border: solid 1px white;
  width: 250px;
  min-height: 350px;
  margin: 2em;

  img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 128px;
    height: 128px;
    border-radius: 25em;
  }
}
</style>
<template>
  <div class="admin_user">
    <div class="users_header" >
      <h2>Dérogations</h2>
    </div>
    <div class="all_users">


      <div class="on_user">
        <div class="">
          <p>Les e-mails suivants peuvent créer un compte sur le site</p>
        </div>

      </div>

      <div class="on_user" >
        <div class="cell" >
          <input type="email" placeholder="Ajouter un nouvel e-mail" name="email" v-model="email">
        </div>
        <div class="cell">
          <div class="bo_btn" @click="addToWhiteList()">
            <p>Ajouter</p>
          </div>
        </div>
      </div>
      <div class="on_user " :class="{even: index % 2 === 0}" v-for="(user, index) in users"  :key="user">
        <div class="cell">
          <p>{{user.email}}</p>
        </div>
        <div class="cell">
          <div class="bo_btn bo_btn_err" @click="removeToWhiteList(user.id)">
            <p>Supprimer !</p>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
const axios = require('axios');
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'AdminUsers',
  data: function () {
    return {
      users: [''],
      token: '',
      myUser: '',
      email: '',
      msg: null

    }
  },
  methods: {
    getAllWhiteList: function () {
      axios.get(process.env.VUE_APP_API + '' + 'adm/white_list')
      .then(response => (this.users = response.data))
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
    removeToWhiteList: function (id) {
      axios.delete(process.env.VUE_APP_API + '' + 'adm/white_list/' + id, {
        //level: newLevel
      })
      this.getAllWhiteList()
    },
    addToWhiteList: function () {
      axios.post(process.env.VUE_APP_API + '' + 'adm/white_list', {
        email: this.email
      })
      this.getAllWhiteList()
      this.email = ''
    }
  },
  mounted () {
    this.token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
    this.myUser = VueJwtDecode.decode(this.token);
    this.getAllWhiteList()
  }
}

</script>

<style scoped lang="scss">

</style>

<template>
  <div class="enter">
    <div class="enterModal">
      <div class="enterContent">
        <div class="social_box">
          <a :href="facebook"><i class="fab fa-facebook-square fa-2x"></i></a>
          <a :href="youtube"><i class="fab fa-youtube fa-2x"></i></a>
          <a :href="instagram"><i class="fab fa-instagram fa-2x"></i></a>
        </div>
        <div class="text_and_logo">
          <div class="logo_box">
            <img class="logo" src="../assets/logolong.svg" alt="Logo d'Audio Soft">
          </div>
          <div class="pres">
            <div id="welcome_text" class="text">
              <p>{{ msg }}</p>
              <div v-if="msg === undefined" class="loading_enter">
                <img src="../assets/loading.svg" alt="loading">
              </div>
            </div>
          </div>

        </div>
        <div @click="toggleModaleEnter(false)" class="arrow_home">
          <img src="../assets/chevronaccueil.svg" alt="Flèche">
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Enter',
  props: {
    msg: String,
    facebook: String,
    youtube: String,
    instagram: String,
    reveleEnter: Boolean,
    toggleModaleEnter: String,
    loadingEnter: Boolean

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

a {
  color: #42b983;
}

.social_box {
  z-index: 4;
  display: flex;
  justify-content: flex-end;
  a {
    opacity: 0.3;
    margin: .3em;
  }
}

.logo_box {
  z-index: 4;
  @media (max-width: 900px) {
    //img {
    //  position: absolute;
    //  top: 1em;
    //}
  }
  @media (min-width: 900px) and (max-width: 1599px) {
    position: absolute;
    left: -9em; top: 60%;
    img {
      height: 400px;
    }

  }
  @media (min-width: 1599px) {
    position: absolute;
    left: 7em; top: 30%;
    img {
      height: 400px;
    }

  }

}

.text_and_logo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80vh;
  @media (min-width: 900px) {
    img {
      position: relative;
      right: 4rem; bottom: 1rem;
    }
  }
  @media (min-width: 600px) and (max-width: 1599px) {
    transform: translateY(-120px);
  }
}

.logo {
  @media (max-width: 900px){
    width: 50%;
    height: auto;
    margin: 0 0 2rem 0;
    margin-left: 26%;
  }
  @media (min-width: 900px) and (max-width: 1599px) {
    transform: rotate(-90deg);
    width: 40%;
    position: relative;
    left: 8em; top: -8em;
  }
  @media (min-width: 1600px){
    transform: rotate(-90deg);
    width: 60%;
  }
}
.enter{
  z-index: 3;
  overflow: auto;
  scrollbar-color: #59546f #fff0;
  scrollbar-width: thin;
  @media (max-width: 900px){
    //text-align: center;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center top url("../assets/homebackground.jpg");
  /*background-position: center top;*/
  background-size: auto 100%;
  @media (min-width: 900px){
    height: 100vh;
    background: url("../assets/homebackground.jpg");
    background-size: auto 100%;
  }
}
.enterContent {
  @media (max-width: 900px) {
    background: center top 15% no-repeat url("../assets/logobackground.svg");
  }
  background-size: 80%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pres {
  z-index: 3;
  //width: 300px;
  height: 300px;
  @media (max-width: 900px) {
    margin-bottom: 5em;
  }
  @media (min-width: 900px) and (max-width: 1599px) {
    position: absolute;
    width: 3450px; height: 920px;
    top: 0; right: 0; /*bottom: 0; left: 0;*/
    background: no-repeat right top url("../assets/cadrecircuitimprimé.svg");
    background-size: 70%;
  }
  //background: no-repeat url("../assets/cadrecircuitimprimételephone.svg");
  @media (min-width: 1600px){
    position: absolute;
    width: 3450px; height: 920px;
    top: 0; right: 0; /*bottom: 0; left: 0;*/
    background: no-repeat right top url("../assets/cadrecircuitimprimé.svg");

  }
}
#welcome_text {
  overflow: auto;
  scrollbar-color: #59546f #fff0;
  scrollbar-width: thin;
  @media (max-width: 900px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  background: #00000055;
  width: 250px;
  @media (min-width: 900px) and (max-width: 1599px) {
    position: absolute;
    top: 163px; right: 278px;
    width: 327px;
    height: 257px;
  }
  @media (min-width: 1600px) {
    position: absolute;
    top: 233px; right: 397px;
    width: 464px;
    height: 369px;
  }
    @media (min-width: 600px) and (max-width: 900px) {
      transform: scale(1.5);
    }

}
#welcome_text p {
  @media (max-width: 900px) {
    padding: 1em;
  }
  margin: 2em;
  font-size: 0.7em;
  @media (min-width: 900px){
    //max-width: 2000px;
    font-size: 1.1em;
  }
}
.loading_enter {
  display: flex;
  justify-content: center;
  img {
    position: relative;
    left: .1em; top: 2em;
  }
}
.arrow_home {
  animation: arrow-y 3s infinite;
  @media (max-width: 899px) {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
  z-index: 4;
  img {
    width: 32px;
    height: auto;
    cursor: pointer;
    transform: scale(2);
  }
  text-align: center;
  @media (min-width: 900px){
    width: 100%;
    img {
      width: 60px;
      height: auto;
      text-align: center;
    }
  }

}
@keyframes arrow-y {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.pres {
  transform-origin: top;
  animation: loadingRight 1000ms;
}
.logo_box {
  transform-origin: top;
  animation: loadingLeft 1000ms;
}
</style>

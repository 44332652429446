<template>
  <div class="admin_user">
    <div class="users_header" >
      <h2>Éléments du menu déroulant</h2>
    </div>
    <div class="all_users">


      <div class="on_user">

      </div>

      <div class="on_user" >
        <div class="cell" >
          <input type="text" placeholder="Titre" name="anchor" v-model="anchor">
        </div>
        <div class="cell" >
          <input type="text" placeholder="URL" name="pathurl" v-model="pathurl">
        </div>
        <div class="cell">
          <div class="bo_btn" @click="addToDropDown()">
            <p>Ajouter</p>
          </div>
        </div>
      </div>
      <div class="on_user " :class="{even: index % 2 === 0}" v-for="(dropDownElement, index) in dropDownElements"  :key="dropDownElement">
        <div class="cell">
          <p>{{dropDownElement.anchor}}</p>
        </div>
        <div class="cell">
          <p>{{dropDownElement.pathurl}}</p>
        </div>
        <div class="cell">
          <div class="bo_btn bo_btn_err" @click="removeToDropDown(dropDownElement.id)">
            <p>Supprimer !</p>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
const axios = require('axios');
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'AdminUsers',
  data: function () {
    return {
      dropDownElements: [''],
      token: '',
      myUser: '',
      anchor: '',
      pathurl: '',
      msg: null

    }
  },
  methods: {
    getAllDropDown: function () {
      axios.get(process.env.VUE_APP_API + '' + 'dropdown')
      .then(response => (this.dropDownElements = response.data))
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
    removeToDropDown: function (idToRemove) {
      axios.delete(process.env.VUE_APP_API + '' + 'dropdown/', {
        data: { id: idToRemove },
        //headers: { "Authorization": "clé d'autorisation" }
      })
      this.getAllDropDown()
    },
    addToDropDown: function () {
      axios.post(process.env.VUE_APP_API + '' + 'dropdown/', {
        anchor: this.anchor,
        pathurl: this.pathurl
      })
      this.getAllDropDown()
      this.anchor = ''
      this.pathurl = ''
    }
  },
  mounted () {
    this.token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
    this.myUser = VueJwtDecode.decode(this.token);
    this.getAllDropDown()
  }
}

</script>

<style scoped lang="scss">

</style>

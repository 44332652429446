<template>
  <div v-if="egg.toasty.activate && myData && indEgg <= egg.toasty.FrequencyOfOccurrence">
    <transition name="toasty">
      <Toasty/>
    </transition>
  </div>
  <div v-show="!open" >
    <div @click="open = !open" class="box_btn_nav_gsm">
      <i class="fas fa-bars fa-2x btn_nav_gsm"></i>
    </div>
  </div>
  <div class="header">
    <transition name="intro">
      <div v-show="open" id="nav_gsm">
        <p @click="open = !open" class="x" >X</p>
        <ul>
          <li><router-link to="/">Galerie</router-link></li>
          <li><router-link to="/means">Ressources & Moyens</router-link></li>
          <li><router-link to="/about">L'équipe</router-link></li>
          <div class="submenu" v-for="dropdownMenuElement in dropdownMenuElements"  :key="dropdownMenuElement" >
            <li> <a target="_blank" :href="dropdownMenuElement.pathurl">{{dropdownMenuElement.anchor}}</a></li>
          </div>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
    </transition>

    <div class="logo_pages">
      <img @click="toggleModaleEnter(true)" src="../assets/logocourt.svg" alt="Logo d'AudioSoft">
    </div>


    <div id="nav">

      <div class="my_avat" v-if="pic !== false">

        <a :href="'#/bo/users/' + id"><img v-if="pic" :src="pic" alt="Moi"><img v-else src="../assets/dafault.png" alt="Moi"></a>

      </div>

      <div v-if="pic === false" v-on:dblclick="showLogin()" class="circle">
      </div>
      <div class="separator">
      </div>

      <div class="btn_nav_left">
      </div>
      <div class="btn_nav" @click="goTo('/')">
        <router-link to="/">Galerie</router-link>
      </div>
      <div class="btn_nav_right">
      </div>

      <div class="separator">
      </div>

      <div class="btn_nav_left">
      </div>
      <div class="btn_nav long_string" @click="goTo('/means')">
        <router-link to="/means">Ressources<br>& Moyens</router-link>
      </div>
      <div class="btn_nav_right">
      </div>

      <div class="separator">
      </div>

      <div class="btn_nav_left">
      </div>
      <div class="btn_nav" @click="goTo('/about')">
        <router-link to="/about">L'équipe</router-link>
      </div>
      <div class="btn_nav_right">
      </div>

      <div class="separator">
      </div>

      <div class="btn_nav_left">
      </div>
      <div class="btn_nav" @click="dropdownMenuElements.open = !dropdownMenuElements.open">
        <p class="a_menu" v-bind:class="{ dropdown_open: dropdownMenuElements.open }" >References</p>
      </div>
      <div class="btn_nav_right">
      </div>

      <div class="separator">
      </div>

      <div class="btn_nav_left">
      </div>
      <div class="btn_nav" @click="goTo('/contact')">
        <router-link to="/contact">Contact</router-link>
      </div>
      <div class="btn_nav_right">
      </div>

      <div class="separator">
      </div>


    </div>
    <div v-show="dropdownMenuElements.open" class="dropdown_menu_box">
      <div class="dropdown_menu">
        <ul>
          <div v-for="dropdownMenuElement in dropdownMenuElements"  :key="dropdownMenuElement">
            <div class="dropdown_menu_element">
              <li><a target="_blank" :href="dropdownMenuElement.pathurl" >{{dropdownMenuElement.anchor}}</a></li>
            </div>

          </div>


        </ul>
      </div>
    </div>
  </div>
</template>

<script>
//const router = this.$router;
const axios = require('axios');
//import VueJwtDecode from 'vue-jwt-decode'
import Toasty from '../components/Toasty.vue'

export default {
  name: 'NavBar',
  components: {
    Toasty
  },
  data: function () {
    return {
      dropdownMenuElements: [{anchor:	""},{pathurl: ""}],
      open: false, //?????
      token: '',
      decodedToken: '',
      myData: false,
      egg: {"toasty":{"activate":true,"FrequencyOfOccurrence":25}},
      indEgg: 0,

    }
  },
  props: {
    msg: String, //////////// ??????????????????????????????,
    toggleModaleEnter: String,
    showLogin: String,
    pic: String,
    id: String,
  },
  methods: {
    goLogin: function() {
      this.$router.push({ path: '/as-login' })

    },
    goTo: function(url) {
      this.$router.push(url);
    },


  },
  mounted () {
    let getDropDown = async() => {
      let dataDropDown = await axios.get(process.env.VUE_APP_API + '' + 'dropdown')
      this.dropdownMenuElements = dataDropDown.data

    }
    getDropDown();

    // Identifier l'utilisateur
    // if (localStorage.getItem('token') != undefined || localStorage.getItem('token') != null || localStorage.getItem('token') != '') {
    //   this.token = localStorage.getItem('token');
    //   axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
    //   this.decodedToken = VueJwtDecode.decode(this.token);
    //   if (this.decodedToken != null) {
    //     axios.get(process.env.VUE_APP_API + '' + 'team/' + this.decodedToken.userId)
    //     .then(response => (this.myData = response.data, this.egg = JSON.parse(response.data[0].egg) ))
    //   }

    // }
    function getRandomIndEgg(max) {
      return Math.floor(Math.random() * max);
    }
    this.indEgg = getRandomIndEgg(100)
    console.log(this.indEgg);
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo_pages{
  @media (min-width: 1500px) {
    z-index: 2;
    transform: scale(1.5);
    position: relative;
    top: 1.5em;
    left: 1em;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    transform: scale(2.5);
    position: relative;
    top: 2em;
    left: 2em;
  }
  img {
    cursor: pointer;
    margin: 1em 3em;;
    width: 50px;
    height: auto;
    @media (min-width: 900px) {
      width: 100px;
    }
  }
}
#nav_gsm, .btn_nav_gsm {
  position: fixed;
  z-index: 2;
}
.submenu {
  li {
    width: 100%;
    text-align: center;
  }
}
#nav_gsm {
  @media (min-width: 1500px){
    display: none;
  }
  z-index: 6;
  top: 0; right: 0; left: 0;
  padding: .5em;
  background: #001024aa;
  ul{
    display: flex;
    flex-direction: column;
    li {
      text-align: center;
      margin: 3px 0;
      a {
        font-size: 1.8em;
      }
    }
  }
}
.btn_nav_gsm {
  top: 18px; right: 38px;
}

.box_btn_nav_gsm{
  @media (min-width: 900px) {
    display: none;
  }
}
.x {
  text-align: right;
  font-weight: bold;
  font-size: 2em;
  margin: -.3em 8px -1em 0
}
#nav, #nav_gsm {
  * {
    font-family: 'Chakra Petch', sans-serif;
    text-transform: uppercase;
  }
}
#nav {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  a, .a_menu {
    text-decoration: none;
    font-weight: bold;
    color: #999;
    cursor: pointer;
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
    -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
    user-select: none; /* Propriété standard */

    &.router-link-exact-active {
      color: #fff;
      text-shadow: #FFF 1px 0 10px;
    }
    @media (min-width: 900px) and (max-width: 1499px) {
      font-size: .8em;
    }
  }
  .dropdown_open {
    color: #fff;
    text-shadow: #FFF 1px 0 10px;
  }
  @media (max-width: 899px) {
    display: none;

  }
  @media (min-width: 900px) and (max-width: 1499px) {
    padding: 6px 0;
    max-height: 58px;
    //transform: scale(.8);
    //transform-origin: right;


  }
  @media (min-width: 1500px) {
    padding: 30px 0;

  }
}
.btn_nav{
  cursor: pointer;
  clip-path: polygon(6% 0px, 94.42% 0px, 100% 100%, 0% 100%);
  width: 100px;
  height: 30px;
  padding: .8em 3.5em;
  border-top: solid 2px;
  background: #00000055;
  display: flex;
  justify-content: center;
  &.active {
    background: #55555566;

  }
  &:hover {
    background: #55555566;
  }
  a, .a_menu{
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (min-width: 900px) and (max-width: 1499px) {
    width: 50px;
    height: 25px;
  }
}
.btn_nav_left {
  width: 2px;
  height: 58px;
  background-color: white;
  transform-origin: bottom;
  transform: rotate(14deg);
  @media (min-width: 900px) and (max-width: 1499px) {
    transform: rotate(12.5deg);
    height: 50px;
  }
}
.btn_nav_right {
  width: 2px;
  height: 58px;
  background-color: white;
  transform-origin: bottom;
  transform: rotate(-13deg);
  @media (min-width: 900px) and (max-width: 1499px) {
    transform: rotate(-13deg);
    height: 50px;
  }
}

.separator{
  width: 2em;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100% 2px;// Pour effet sousligné
  @media (min-width: 900px) and (max-width: 1499px) {
    background-position: 50% 87%;
    width: 1em;
  }
}

.circle {
  width: 9px;
  height: 9px;
  border-radius: 5em;
  border: solid 2px;
  position: relative;
  top: 50px;
  @media (min-width: 900px) and (max-width: 1499px) {
    top: 44px;

  }

}

.long_string{
  a {
    margin-top: -.3em;
    margin-left: 1em;

  }
}

.dropdown_menu_box {
  z-index: 9;
  position: fixed;
  top: 5.5em; right: 17.6em;
  width: 13.2em;
  background: #00000055;
  @media (min-width: 900px) and (max-width: 1499px) {
    top: 3.69em; right: 12.8em;
    width: 10.3em;
  }
}
.dropdown_menu {
  ul{
    display: flex;
    flex-direction: column;
  }
  li{
    * {
      font-family: 'Chakra Petch', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      @media (min-width: 900px) and (max-width: 1499px) {
        font-size: 0.8em;
      }
    }
  }
}
/*.dropdown_menu_element {
  cursor: pointer;
  &:hover{
    background-color: #ffffff55;
  }
}*/

.my_avat {
  position: relative;
  top: 30px; right: 0px;
  img {
    max-height: 45px;
    width: 45px;
    border-radius: 5em;
    border: solid white 3px;

  }
}

@keyframes toasty {
  0% { transform: translateX(+100%);}
  100% { transform: translateX(0);}
}
.toasty-enter-active {
  animation: toasty 400ms;
}
.toasty-leave-active {
  animation: toasty 400ms reverse;
}
</style>

<template>
  <div v-if="means[0].url_pic != undefined" id="Means" class="Page Means">
    <h1 class="just_for_gsm" >Moyens</h1>
    <div class="means_box_gsm just_for_gsm all_means_gsm">
      <div v-for="mean in means"  :key="mean" class="mean_box_gsm">
        <img :src="mean.url_pic" :alt="mean.title">
        <div class="text_mean_gsm">
          <h3>{{mean.title}}</h3>
          <p>{{mean.description}}</p>
        </div>
      </div>
    </div>
    <h1 class="just_for_desk" >Ressources<br>& Moyens</h1>
    <div class="means_box">
      <div class="mean_text">
        <h3>Nous mettons à votre disposition l'ensemble de nos ressources humaines et matérielles.</h3>
        <div class="mean_description">
          <p>{{text}}</p>
        </div>
      </div>

      <div class="all_means">
        <div v-for="(mean, indexMean) in means"  :key="mean" class="mean_box">
          <div @click="textMean(indexMean)" class="mean">
            <img :class="{active_mean: index === indexMean}" :src="mean.url_pic" :alt="mean.title">
            <div :class="{active_mean: index === indexMean}">
              <h4 :class="' mean_'+indexMean"> {{ mean.title }}</h4>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
  <router-link to="/legales" class="link_legales">MENTIONS LÉGALES</router-link>
</template>

<script>
// @ is an alias to /src
const axios = require('axios');

export default {
  name: 'Means',
  components: {
  },
  data: function () {
    return {
      means: [''],
      text: null,
      index: 0,
    }
  },
  methods: {
    textMean: function(indMean) {
      this.text = this.means[indMean].description;
      this.index = indMean;
    },
  },
  mounted () {
    axios.get(process.env.VUE_APP_API + '' + 'means')
    .then(response => {
      this.means = response.data;
      this.text = response.data[0].description

    }

    )
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  },
}
</script>

<style lang="scss">
#Means {
  z-index: 0;
  position: fixed;
  top:0; right: 0; bottom: 0; left: 0;
  background: url("../assets/ressourcesbackground.jpg");
  background-size: 100% 100%;

}

@media (max-width: 1499px) {
  .means_box {
    display: none;
  }
  .means_box_gsm {
    margin: 3em 2em 3em 2em;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .mean_box_gsm {
    overflow: auto;
    scrollbar-color: #59546f #fff0;
    scrollbar-width: thin;
    width: 20em;
    height: 20em;
    margin: 3em 2em 3em 2em;
    padding: 4em;
    background-color: #001024aa;
    h3 {
      text-align: center;
      font-size: 25px;
      margin-top: 2px;
    }
    img {
      width: 8em;
      height: 8em;
      margin-left: 29%;
      border-radius: 25em;
      margin-top: auto;
      margin-bottom: auto;
    }

  }
}
@media (max-width: 500px) {
  #Means {
    overflow: auto;
    background-size: auto 100%;
  }
  .means_box, .means_box_gsm, .mean_box_gsm {
    transform: scale(.8);
  }
  .all_means_gsm {
    margin: 0
  }
  .mean_box_gsm {
    width: 90%;
    transform: translateY(-250px);
    margin: 15px 0;
    padding: 10px;
  }
}
@media (min-width: 1500px) {
  #Means {
    background-size: 100% auto;
    h3{
      font-size: 1.5em;
    }
  }
  .means_box{
    margin-top: 3em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .all_means{
    background: no-repeat url("../assets/barreressourcesetmoyens.svg");
    background-position: left 52px bottom 0px;
    height: 80vh;
  }
  .mean_box{
    margin: 1em 0;
  }
  .mean{
    margin-bottom: 2rem;
    width: 23em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
    h4 {
      position: relative;
      top: 1em;
      text-transform: capitalize;
      opacity: .5;
      transition: all .3s ease-out;
    }
    .active_mean {
      h4{
        text-shadow: #FFF 1px 0 10px;
        opacity: 1;
      }
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50em;
      position: relative;
      right: 4em;
      filter: saturate(0);
      transform: scale(.9);
      transition: all .3s ease-out;
      &.active_mean {
        transform: scale(1.2);
        filter: saturate(1);
      }
    }
  }
  .mean_description{
    z-index: 6;
    overflow: auto;
    max-height: 50vh;
    margin: 3em;
    width: 57em;
    padding: 1rem 3rem;
    background-color: #001024aa;
    scrollbar-color: white #00102400;
    scrollbar-width: thin;

    p{
      font-size: 1.3em;
    }
  }
  .all_means {
    transform-origin: top;
    animation: loadingRight 1000ms;
  }
  .mean_text {
    transform-origin: top;
    animation: loadingLeft 1000ms;
  }
  .mean_0 {
    left: 1em;
  }
  .mean_1 {
    right: 2em;
  }
  .mean_2 {
    left: 1em;
  }
  .mean_3 {
    left: 2.8em;
  }
  .mean_4 {
    right: 2em;
  }
  .mean_5 {
    left: 2.8em;
  }

}
</style>

<template>
    <div class="mes">
        <p>{{ mes }}</p>
    </div>

</template>

<script>
    export default {
        name: 'Mess',
        props: {
            mes: String
        }
        
    }

</script>

<style scoped lang="scss">
.mes{
    margin: 0 2em;
    background: #223041;
    border-left: solid 4px rgb(6, 196, 6);
    padding: 1rem;
    *{
        color: rgb(6, 196, 6);
    }
}
</style>
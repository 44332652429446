<template>
    <div class="bo" >
      <h1>Gestion de la galerie</h1>
      <div class="gallery_admin_box">
      <router-link to="/bo/gallery/new"><p><i class="fas fa-plus"></i> Ajouter un nouvel élément</p></router-link>
      <div class="gallery_admin_card" v-for="elementGallery in elementsGallery"  :key="elementGallery">
        <div class="gallery_admin_parent">
          <router-link :to="{ name: 'BoGalleryUpdate', params: { id: elementGallery.id }}"><p>{{elementGallery.title}}</p></router-link>
          <div class="ctrlGalleryNeo">
            <div class="">
              <router-link :to="{ name: 'BoGalleryUpdate', params: { id: elementGallery.id }}"><i class="fas fa-pen-fancy fa-2x"></i></router-link>
            </div>
            <div @click="removeElement(elementGallery.id)" class="">
              <i class="far fa-trash-alt fa-2x pointer"></i>
            </div>
          </div>

        </div>
        <hr>
        <div id="gallery_admin_child" class="gallery_admin_child" v-for="(elementGalleryChild, index) in elementsGalleryChild"  :key="elementGalleryChild" >
          <div :id="elementGalleryChild.id" class="gallery_admin_child_line" v-if="elementGalleryChild.attachment == elementGallery.id ">
            <router-link :to="{ name: 'BoGalleryUpdate', params: { id: elementGalleryChild.id }}"><p>{{elementGalleryChild.title}}</p></router-link>
            <div class="ctrlGalleryNeoChild">
              <router-link :to="{ name: 'BoGalleryUpdate', params: { id: elementGalleryChild.id }}"><p><i class="fas fa-pen-fancy"></i></p></router-link>
              <p @click="removeElement(elementGalleryChild.id, index)" ><i class="far fa-trash-alt"></i></p>
            </div>
          </div>
        </div>
        <div class="">
          <router-link :to="{ name: 'BoGalleryNewChild', params: { id: elementGallery.id }}"><p><i class="fas fa-plus"></i> Ajouter un sous-élément</p></router-link>
        </div>
      </div>
    </div>
      
    </div>
    
</template>

<script>
//import BoAudienceLight from '../components/BoAudienceLight.vue'
const axios = require('axios');

export default {
  name: 'BoGallery',
  components: {
    //BoAudienceLight
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {
      token: '',
      myUser: '',
      elementsGallery: '',
      elementsGalleryChild: '',
      msg: '',
    }
  },
  methods: {
    getAllEmentsToGallery: function () {
      axios.get(process.env.VUE_APP_API + '' + 'gallery')
      .then(response => (this.elementsGallery = response.data))
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    
      axios.get(process.env.VUE_APP_API + '' + 'gallery/all')
      .then(response => (this.elementsGalleryChild = response.data))
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
    removeElement: function (idToRemove) {
      console.log(idToRemove);

      axios.delete(process.env.VUE_APP_API + '' + 'gallery/remove/' + idToRemove,)
      .then(function (response) {
        console.log(response);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
        this.getAllEmentsToGallery()
    }
  },
  mounted () {
    // Identifier l'utilisateur
    this.token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
    this.getAllEmentsToGallery()
  }
}
</script>
<style scoped lang="scss">

.gallery_admin_card{
  border: solid 1px;
}

.admin_page {
  overflow: auto;

}
.gallery_admin_box {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  width: 80%;
  height: 600px;

}
.gallery_admin_card {
  margin: 1em 0;
  padding: 1em;
  background-color: #001024cc;
}
.gallery_admin_parent {
  display: flex;
  justify-content: space-between;
  p {
    font-size: 1.2em;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: bold;
  }
}
.ctrlGalleryNeo {
  display: flex;
  justify-content: flex-end;
  * {
    margin: .1em .1em;
  }
}
.gallery_admin_child_line, .ctrlGalleryNeoChild {
  display: flex;
}
.gallery_admin_child_line {
  padding: 3px;
  justify-content: space-between;
  &:hover {
    background-color: #ffffff33;
  }
}
.ctrlGalleryNeoChild {
  cursor: pointer;
  p {
    margin-left: 15px;
    &:hover {
      background-color: #001024;
      color: black;
    }
  }

}
.pointer {
  cursor: pointer;
}

</style>
<template>
  <div class="bo" >
    <h1>Ajouter un élément à la galerie</h1>
    <BoGalleryForm />
  </div>
    
</template>

<script>
import BoGalleryForm from '@/components/BoGalleryForm.vue'

export default {
  name: 'BoGallery',
  components: {
    BoGalleryForm
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
<style scoped lang="scss">


</style>
<template>
    <div class="bo">
        <h1>{{ name }}</h1>
        <div class="pass_box">
            <div class="bo_btn" v-if="!passUpdate" @click="updatePass()" >
                <p>Modifier mon mot de passe en cliquant ici</p>
            </div>
            <p v-if="passUpdate">Mot de passe modifier</p>
        </div>
        <section>
            <div class="header_input" >
                <h2>Mes Infos</h2>
            </div>
            <form class="" method="post" @submit.prevent="submitPost">
                <img :src="url_pic" :alt="name">
                <section class="first">
                    <div class="header_input" >
                        <h2>Infos principales</h2>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="file">Avatar</label>
                            <input type="file" id="file" ref="file" name="media" placeholder="Media" @change="handleFileUpload()">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="name">Nom et Prénom</label>
                            <input type="text" name="name" placeholder="name" v-model="name">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="email">E-mail</label>
                            <input type="text" name="email" placeholder="email" v-model="email">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="job">Poste</label>
                            <input type="text" name="job" placeholder="job" v-model="job">
                        </div>
                    </div>
                    
                </section>
                <section class="social" >
                    <div class="header_input" >
                        <h2>Réseaux sociaux</h2>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="facebook">Facebook</label>
                            <input type="text" name="facebook" placeholder="facebook" v-model="facebook">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="youtube">Youtube</label>
                            <input type="text" name="youtube" placeholder="youtube" v-model="youtube">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="instagram">Instagram</label>
                            <input type="text" name="instagram" placeholder="instagram" v-model="instagram">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="twitter">Twitter</label>
                            <input type="text" name="twitter" placeholder="twitter" v-model="twitter">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="youtube">Youtube</label>
                            <input type="text" name="youtube" placeholder="youtube" v-model="youtube">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="linkedin">Linkedin</label>
                            <input type="text" name="linkedin" placeholder="linkedin" v-model="linkedin">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="pinterest">Pinterest</label>
                            <input type="text" name="pinterest" placeholder="pinterest" v-model="pinterest">
                        </div>
                    </div>
                    <div class="inputs_container" >
                        <div class="item" >
                            <label for="mastodon">Mastodon</label>
                            <input type="text" name="mastodon" placeholder="mastodon" v-model="mastodon">
                        </div>
                    </div>
                    
                </section>

                <input class="button" type="submit" value="Envoyer" />
            </form>
        </section>


        
    </div>
</template>

<script>
//const axios = require('axios');


export default {
    name: 'Admin',
    data: function () {
        return {
            file: '',
            name: '',
            email: '',
            job: '',
            description: '',
            facebook: '',
            youtube: '',
            instagram: '',
            twitter: '',
            linkedin: '',
            pinterest: '',
            mastodon: '',
            url_pic: '',
            passUpdate: false
        }
    },
    methods: {
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        updatePass() {
            const newPass = window.prompt("Entrez votre nouveau mot de passe :");
            if (newPass === '' || newPass === null) {
                console.error('Opération annulée !');
            } else {
                const axios = require('axios');
                axios.put(process.env.VUE_APP_API + 'team/mdp/' + this.$route.params.id, {
                    password: newPass
                })
                this.passUpdate = !this.passUpdate;
            }
        },

        submitPost: function () {
            const router = this.$router;
            let formData = new FormData();
            formData.append('avatar', this.file);
            formData.append('name', this.name);
            formData.append('email', this.email);
            formData.append('job', this.job);
            formData.append('description', this.description);
            formData.append('facebook', this.facebook);
            formData.append('youtube', this.youtube);
            formData.append('instagram', this.instagram);
            formData.append('twitter', this.twitter);
            formData.append('linkedin', this.linkedin);
            formData.append('pinterest', this.pinterest);
            formData.append('mastodon', this.mastodon);
            console.log(formData);
            const axios = require('axios');
            axios.put(process.env.VUE_APP_API + '' + 'team/update/' + this.$route.params.id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            router.push("../as-admin-gallery");
        },

    },
    mounted() {
        // Identifier l'utilisateur
        const axios = require('axios');
        this.token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;

        //Récupérer les infos dans la DB
        axios.get(process.env.VUE_APP_API + '' + 'team/' + this.$route.params.id)
            .then(response => (
                this.name = response.data[0].name,
                this.email = response.data[0].email,
                this.job = response.data[0].job,
                this.description = response.data[0].description,
                this.facebook = response.data[0].facebook,
                this.youtube = response.data[0].youtube,
                this.instagram = response.data[0].instagram,
                this.twitter = response.data[0].twitter,
                this.linkedin = response.data[0].linkedin,
                this.pinterest = response.data[0].pinterest,
                this.mastodon = response.data[0].mastodon,
                this.url_pic = response.data[0].url_pic

            ))
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    },
}

</script>

<style lang="scss" scoped >
.my_account_box {
    //width: 120em;
    display: flex;
    justify-content: center;
    flex-direction: row;
    //background: #001024aa;
    // position: relative;
    // bottom: 3.5em;
}

// .edit_my_pic {
//     img {
//         height: 25em;
//         width: auto;
//         margin: 10em 0 0 0;
//         //border: solid white 25px;
//     }
// }

// .edit_my_account {
//     padding: 1em 5em 5em 5em;
//     z-index: 6;
//     margin: 5em 0;
//     width: 50%;
    
//     overflow: auto;

//     h3 {
//         font-size: 2em;
//         margin: .4em 0;
//         ;
//     }
// }

// .pass_box {
//     display: flex;
//     justify-content: flex-end;
// }

// .new_pass {
//     display: block;
//     background-color: #001024;
//     padding: 8px;
//     cursor: pointer;
//     transition: all .4s ease-out;

//     &:hover {
//         background-color: white;
//         color: #001024;
//         text-decoration: underline;
//     }
// }
.pass_box{
    margin: 0 1.5rem;
}
.first {
    //width: 40%;
}
form {
    img {
        width: 400px;
        height: auto;
        padding: 0 2em
    };
}
.social{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}
</style>

<template>
  <div id="Contact" class="Page Contact">
    <h1>Contact</h1>
    <div class="contact_page">
      <div v-if="res !== ''" class="response">
        <p>{{res}}</p>
      </div>
      <div v-if="res === ''" class="contact_form">
        <form method="post" @submit.prevent="submitContact">
          <input type="text" name="name" placeholder="Nom" v-model="name">
          <input type="text" name="subject" placeholder="Objet" v-model="subject">
          <input type="email" name="email" placeholder="Adresse e-mail" v-model="email">
          <textarea name="text" placeholder="Message" rows="8" cols="80" v-model="text"></textarea>
          <div class="form_btn">
            <input type="submit" value="Envoyer" />
          </div>
        </form>

      </div>
      <div class="infos">
        <h3>Infos</h3>
        <p>{{ audioSoftInfos[0].address }}</p>
        <p>{{ audioSoftInfos[0].post_code }} {{ audioSoftInfos[0].city }}</p>
        <br />
        <p>Tel : {{ phone }}</p>
        <p>e-mail : {{ audioSoftInfos[0].email }}</p>
      </div>
    </div>
  </div>
  <router-link to="/legales" class="link_legales">MENTIONS LÉGALES</router-link>
</template>

<script>
// @ is an alias to /src
const axios = require('axios');

export default {
  name: 'Contact',
  components: {
  },
  data: function () {
    return {
      res: "",
      audioSoftInfos: [''],
      name: "",
      subject: "",
      email: "",
      text: "",
      phone: "",
    }
  },
  mounted () {

    axios.get(process.env.VUE_APP_API + '')
    .then(response => (this.audioSoftInfos = response.data, this.phone = response.data[0].phone.replace(/(.{2})(?=.)/g,"$1 "))) // Une Regex pour éspacer les numéros du tel tout les deux chifres
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  },
  methods: {
    submitContact: function() {
      axios.post(process.env.VUE_APP_API + '' + 'email/', {
        name: this.name,
        subject: this.subject,
        email: this.email,
        text: this.text
      })
      .then(response => (this.res = response.data.res))

      .catch(error => (this.res = error.data))

      this.form=false;
    }
  },
}
</script>

<style lang="scss">
  #Contact {
    z-index: 0;
    position: fixed;
    top:0; right: 0; bottom: 0; left: 0;
    background: url("../assets/contactbackground.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media (max-width: 899px) {
      overflow: auto;
      background-size: auto 100%;
    }

  }
    @media (max-width: 899px) {
      .infos {
        margin-left: auto;
        margin-right: auto;
        width: 15em;
        height: 20em;
        padding: .1em 3em;
        background: #59546faa;
        h3 {
          font-size: 1.5em;
        }
      }
      input, textarea {
        font-size: 15px;
        margin: .5em 1em;
      }
    }
  @media (min-width: 800px) and (max-width: 1499px) {
    .contact_page {
      transform: translateY(140px);
    }
  }
  @media (min-width: 900px) and (max-width: 1499px) {
    .contact_page {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .contact_form {
      margin: 8em 3em 3em 3em;
      max-width: 30em;
    }

    .infos {
      margin: 3em 0;
      width: 28em;
      height: 28em;
      padding: .1em 3em;
      background: #59546faa;
      p{
        font-size: 1.8em;
        line-height: 3px;
      }
      h3 {
        position: relative;
        bottom: 1.5em;
      }
    }
    .infos {
      transform-origin: top;
      animation: loadingRight 1000ms;
    }
    .contact_form {
      transform-origin: top;
      animation: loadingLeft 1000ms;
    }
    .response {
      width: 55em;
      height: 55em;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      p {
        font-size: 2em;
        background-color: #001024;

      }
    }
  }

  @media (min-width: 1500px) {

    .contact_page {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .contact_form {
      margin: 8em 3em 3em 3em;
      max-width: 55em;
    }

    .infos {
      position: absolute;
      bottom: 4em; right: 0;
      width: 28em;
      height: 28em;
      padding: .1em 3em;
      background: #59546faa;
      p{
        font-size: 1.8em;
        line-height: 3px;
      }
      h3 {
        position: relative;
        bottom: 1.5em;
      }
    }
    .infos {
      transform-origin: top;
      animation: loadingRight 1000ms;
    }
    .contact_form {
      transform-origin: top;
      animation: loadingLeft 1000ms;
    }
    .response {
      width: 55em;
      height: 55em;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      p {
        font-size: 2em;
        background-color: #001024;

      }
    }
  }
</style>

<template>
  <div id="about" class="Page about">
    <h1>L'équipe</h1>
    <div v-if="audioSoftTeamMembers" class="audioSoftMembers">
      <div v-for="(audioSoftTeamMember, index) in audioSoftTeamMembers" :key="audioSoftTeamMember.id" class="oneMemberBox">
        <div class="oneMemberAndLine">
          <div class="oneMember">
            <div :class="'space_height_random'+audioSoftTeamMembers.length+'Ind'+index">
              <!-- Espace aléatoir -->
            </div>
            <img v-if="audioSoftTeamMember.url_pic" :src="audioSoftTeamMember.url_pic" :alt="audioSoftTeamMember.name">
            <img v-if="!audioSoftTeamMember.url_pic" src="../assets/dafault.png" :alt="audioSoftTeamMember.name">
            <ul>
              <li><h3>{{audioSoftTeamMember.name}}</h3></li>
              <li v-if="audioSoftTeamMember.job != null" >{{audioSoftTeamMember.job}}</li>
            </ul>
            <ul>
              <!--BOF BOF --><li v-if="audioSoftTeamMember.email != 'othelo@audiosoft.fack' && audioSoftTeamMember.email !=''" ><a :href="'mailto:'+audioSoftTeamMember.email"><i class="fas fa-envelope fa-2x"></i></a></li>
              <li v-if="audioSoftTeamMember.facebook != null && audioSoftTeamMember.facebook !='' && audioSoftTeamMember.facebook != 'null'" ><a :href="audioSoftTeamMember.facebook"><i class="fab fa-facebook fa-2x"></i></a></li>
              <li v-if="audioSoftTeamMember.instagram != null && audioSoftTeamMember.instagram !='' && audioSoftTeamMember.instagram != 'null'" ><a :href="audioSoftTeamMember.instagram"><i class="fab fa-instagram fa-2x"></i></a></li>
              <li v-if="audioSoftTeamMember.twitter != null && audioSoftTeamMember.twitter !='' && audioSoftTeamMember.twitter != 'null'" ><a :href="audioSoftTeamMember.twitter"><i class="fab fa-twitter fa-2x"></i></a></li>
              <li v-if="audioSoftTeamMember.linkedin != null && audioSoftTeamMember.linkedin !='' && audioSoftTeamMember.linkedin != 'null'" ><a :href="audioSoftTeamMember.linkedin"><i class="fab fa-linkedin-in fa-2x"></i></a></li>
              <li v-if="audioSoftTeamMember.pinterest != null && audioSoftTeamMember.pinterest !='' && audioSoftTeamMember.pinterest != 'null'" ><a :href="audioSoftTeamMember.pinterest"><i class="fab fa-pinterest fa-2x"></i></a></li>
              <li v-if="audioSoftTeamMember.youtube != null && audioSoftTeamMember.youtube != '' && audioSoftTeamMember.youtube != 'null'" ><a :href="audioSoftTeamMember.youtube"><i class="fab fa-youtube fa-2x"></i></a></li>
              <li v-if="audioSoftTeamMember.mastodon != null && audioSoftTeamMember.mastodon !='' && audioSoftTeamMember.mastodon != 'null'" > <a :href="audioSoftTeamMember.mastodon"><i class="fab fa-mastodon fa-2x"></i></a></li>
            </ul>
          </div>

        </div>
      </div>


    </div>
  </div>
  <router-link to="/legales" class="link_legales">MENTIONS LÉGALES</router-link>
</template>

<script>
const axios = require('axios');

export default {
  name: 'About',
  components: {
  },
  data: function () {
    return {
      audioSoftTeamMembers: null,

    }
  },
  mounted () {
    axios.get(process.env.VUE_APP_API + '' + 'team/')
    .then(response => (this.audioSoftTeamMembers = response.data))
    .catch(function (error) {
      // handle error
      console.log(this.audioSoftTeamMembers);
      console.log(error);
    })

  }
}
</script>

<style lang="scss">
#about {
  z-index: 0;
  position: fixed;
  top:0; right: 0; bottom: 0; left: 0;
  background: url("../assets/teambackground.jpg");
  background-size: 100% 100%;
  @media (max-width: 1499px) {
    overflow: auto;
    background-size: auto 100%;
  }

}
.oneMemberAndLine {
  display: flex;
  flex-direction: row;
}
.audioSoftMembers {
  margin-top: 15em;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1500px) {
    flex-direction: row;
    background: no-repeat url("../assets/barreequipe.svg");

  }
  justify-content: space-around;
  h3 {
    font-size: 1.3em;
    margin-top: 0;
  }
}
.oneMemberBox {
  @media (max-width: 1499px) {
    margin-left: auto;
    margin-right: auto;
  }
}
.oneMember {
  z-index: 1;
  position: relative;
  bottom: 12em;
  width: 175px;
  img {
    width: 150px;
    height: auto;
    //height: 226px;
    border: solid 10px white;
  }
  li {
    text-transform: capitalize;
    font-variant: small-caps;
  }
  @media (max-width: 1499px) {
    background: #001024aa;
    padding: 2em;
    margin-bottom: 1em;
  }
}
// Gestion aléatoir des éspaces au dessus des avatars des menbres de l'équipe
@media (min-width: 1500px) {
  // Pour 1 menbre
  .space_height_random1Ind0 {
    height: 6em;
  }

  // Pour 2 menbres
  .space_height_random2Ind0 {
    height: 16em;
  }
  .space_height_random2Ind1 {
    height: 16em;
  }

  // Pour 3 menbres
  .space_height_random3Ind0 {
    height: 14em;
  }
  .space_height_random3Ind1 {
    height: 8em;
  }
  .space_height_random3Ind2 {
    height: 14em;
  }

  // Pour 4 menbres
  .space_height_random4Ind0 {
    height: 14em;
  }
  .space_height_random4Ind1 {
    height: 8em;
  }
  .space_height_random4Ind2 {
    height: 18em;
  }
  .space_height_random4Ind3 {
    height: 10em;
  }

  // Pour 5 menbres
  .space_height_random5Ind0 {
    height: 8em;
  }
  .space_height_random5Ind1 {
    height: 14em;
  }
  .space_height_random5Ind2 {
    height: 8em;
  }
  .space_height_random5Ind3 {
    height: 20em;
  }
  .space_height_random5Ind4 {
    height: 10em;
  }

  // Pour 6 menbres
  .space_height_random6Ind0 {
    height: 8em;
  }
  .space_height_random6Ind1 {
    height: 14em;
  }
  .space_height_random6Ind2 {
    height: 10em;
  }
  .space_height_random6Ind3 {
    height: 10em;
  }
  .space_height_random6Ind4 {
    height: 18em;
  }
  .space_height_random6Ind5 {
    height: 10em;
  }

}


</style>

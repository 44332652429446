import { createRouter, createWebHashHistory } from 'vue-router'
import FoGallery from '../views/FoGallery.vue'
import FoTeamMembers from '../views/FoTeamMembers.vue'
import FoMeans from '../views/FoMeans.vue'
import FoContact from '../views/FoContact.vue'
import FoLegales from '../views/FoLegales.vue'
import BoHome from '../views/BoHome.vue'
import BoParamSite from '../views/BoParamSite.vue'
import BoGallery from '../views/BoGallery.vue'
import BoGalleryNew from '../views/BoGalleryNew.vue'
import BoGalleryNewChild from '../views/BoGalleryNewChild.vue'
import BoGalleryUpdate from '../views/BoGalleryUpdate.vue'
import BoMean from '../views/BoMean.vue'
import BoMeanNew from '../views/BoMeanNew.vue'
import BoMeanUpdate from '../views/BoMeanUpdate.vue'
import BoUsers from '../views/BoUsers.vue'
import BoDropDown from '../views/BoDropDown.vue'
import BoOneUser from '../views/BoOneUser.vue'
import BoUpdateUser from '../views/BoUpdateUser.vue'
import BoLogin from '../views/BoLogin.vue'
import ActivateAccount from '../views/ActivateAccount.vue'
import EggMatrix from '../views/EggMatrix.vue'

const routes = [
  {
    path: '/',
    name: 'FoGallery',
    component: FoGallery
  },
  {
    path: '/about',
    name: 'FoTeamMembers',
    component: FoTeamMembers,
    props: { toto: true}
  },
  {
    path: '/means',
    name: 'Means',
    component: FoMeans
  },
  {
    path: '/contact',
    name: 'Contact',
    component: FoContact
  },
  {
    path: '/legales',
    name: 'FoLegales',
    component: FoLegales
  },
  {
    path: '/bo',
    name: 'BoHome',
    component: BoHome
  },
  {
    path: '/bo/site',
    name: 'BoParamSite',
    component: BoParamSite
  },
  {
    path: '/bo/gallery',
    name: 'BoGallery',
    component: BoGallery
  },
  {
    path: '/bo/gallery/new',
    name: 'BoGalleryNew',
    component: BoGalleryNew
  },
  {
    path: '/bo/gallery/new/:id',
    name: 'BoGalleryNewChild',
    component: BoGalleryNewChild
  },
  {
    path: '/bo/gallery/update/:id',
    name: 'BoGalleryUpdate',
    component: BoGalleryUpdate
  },
  {
    path: '/bo/mean',
    name: 'BoMean',
    component: BoMean
  },
  {
    path: '/bo/mean/new',
    name: 'BoMeanNew',
    component: BoMeanNew
  },
  {
    path: '/bo/mean/update/:id',
    name: 'BoMeanUpdate',
    component: BoMeanUpdate
  },
  {
    path: '/bo/users',
    name: 'BoUsers',
    component: BoUsers
  },
  {
    path: '/bo/drop-down',
    name: 'BoDropDown',
    component: BoDropDown
  },
  {
    path: '/bo/users/:id',
    name: 'BoOneUser',
    component: BoOneUser
  },
  {
    path: '/bo/update-users/:id',
    name: 'BoUpdateUser',
    component: BoUpdateUser
  },
  {
    path: '/bo/as-login',
    name: 'BoLogin',
    component: BoLogin
  },
  {
    path: '/activate/:email/:token',
    name: 'ActivateAccount',
    component: ActivateAccount
  },
  {
    path: '/matrix',
    name: 'EggMatrix',
    component: EggMatrix
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router

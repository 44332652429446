<template>
  <div class="bo">
    <h1>Gestion du site</h1>
    <div class="infos_site_adm">
      <form class="" method="post" @submit.prevent="submitPost" >
        <section id="contact" >
          <div class="header_input" >
            <h2>Contact</h2>
          </div>
          <div class="inputs_container" >
            <div class="item" >
              <label for="email">E-mail de contact</label>
              <input id="email" class="medium_input" type="email" name="email" placeholder="email de contact" v-model="email">
            </div>
            <div class="item" >
              <label for="phone">N° de Tel.</label>
              <input id="phone" class="litle_input" type="text" name="phone" placeholder="phone" v-model="phone">
            </div>
          </div>
        </section>

        <section id="adress" >
          <div class="header_input" >
            <h2>Adresse</h2>
          </div>
          <div class="inputs_container" >
            <div class="item" >
              <label for="address">N° et rue</label>
              <input id="address" class="big_input" type="text" name="address" placeholder="address" v-model="address">
            </div>
            <div class="item" >
              <label for="post_code">Code postale</label>
              <input id="post_code" class="litle_input" type="text" name="post_code" placeholder="post_code" v-model="post_code">
            </div>
            <div class="item" >
              <label for="city">Ville</label>
              <input id="city" class="medium_input" type="text" name="city" placeholder="city" v-model="city">
            </div>
  
          </div>

        </section>
        <section id="social_networks">
          <div class="header_input" >
            <h2>Réseaux Sociaux</h2>
          </div>

          <div class="inputs_container" >
            <div class="item" >
              <label for="facebook">Facebook</label>
              <input id="facebook" class="medium_input" type="text" name="facebook" placeholder="Facebook" v-model="facebook">

            </div>
            <div class="item" >
              <label for="youtube">Youtube</label>
              <input id="youtube" class="medium_input" type="text" name="youtube" placeholder="youtube" v-model="youtube">
            </div>
            <div class="item" >
              <label for="instagram">Instagram</label>
              <input id="instagram" class="medium_input" type="text" name="instagram" placeholder="instagram" v-model="instagram">
            </div>

          </div>

        </section>

        <section id="welcome">
          <div class="header_input" >
            <h2>Page d'accueil</h2>
          </div>
          <div class="inputs_container" >
            <div class="item" >
              <label for="welcome_text">Texte d'acceuil</label>
              <textarea id="welcome_text" name="welcome_text" rows="15" cols="80" v-model="welcome_text"></textarea>
            </div>

          </div>
        </section>
        <input v-if="!mes && !err" class="button" type="submit" value="Valider les changements" />
      </form>
      <Res v-if="mes" :mes="mes" />
      <p class="err" v-if="err">{{err}}</p>
    </div>
  </div>
</template>

<script>
//import BoAudienceLight from '../components/BoAudienceLight.vue'
//const axios = require('axios');
import Res from '@/components/ResApi.vue'

export default {
  name: 'BoGallery',
  components: {
    Res
  },
  props: {
    //varShowLogin: String,
  },
  data: function () {
    return {
      email: '',
      phone: '',
      city: '',
      post_code: '',
      address: '',
      facebook: '',
      youtube: '',
      instagram: '',
      welcome_text: '',
      mes: null,
      err: null
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    submitPost: function () {
      const axios = require('axios');
      axios.put(process.env.VUE_APP_API + '1', {
        email: this.email,
        phone: this.phone,
        city: this.city,
        post_code: this.post_code,
        address: this.address,
        facebook: this.facebook,
        youtube: this.youtube,
        instagram: this.instagram,
        welcome_text: this.welcome_text

      })
      .then(response => (this.mes = 'Les infos ont été mises à jour. :-)', console.log(response.data)))
      .catch(response => (this.err = 'Oulala, il y a eu un pépin avec l\'API :-( ' + response.data))
    },
  },
  mounted () {
    // Identifier l'utilisateur
    const axios = require('axios');
    this.token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;

    //Récupérer les infos dans la DB
    axios.get(process.env.VUE_APP_API + '')
    .then(response => (
      this.email = response.data[0].email,
      this.phone = response.data[0].phone,
      this.city = response.data[0].city,
      this.post_code = response.data[0].post_code,
      this.address = response.data[0].address,
      this.facebook = response.data[0].facebook,
      this.youtube = response.data[0].youtube,
      this.instagram = response.data[0].instagram,
      this.welcome_text = response.data[0].welcome_text
    ))
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }
}
</script>
<style scoped lang="scss">

input, label {
  margin: 0;
}
.button {
  cursor: pointer;
  margin: 0 2rem;
  color: #001024;
  background: #FFFFFF;
  &:hover {
    box-shadow: #FFF 1px 0 10px;
  }
}


</style>